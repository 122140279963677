import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import AssetInfoShare from '../AssetInfoShare';
import renderHtml from 'html-react-parser';
import {
  DefaultPromisedWebSocketFactory,
  DefaultDOMWebSocketFactory,
  FullJitterBackoff,
  ReconnectingPromisedWebSocket
} from 'amazon-chime-sdk-js';
import * as config from '../../config';
// Styles
import './Chat.css';
import { approvedAsset } from '../../redux/reducers/reducer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import moment from 'moment';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  }
};
let firstname = localStorage.getItem("firstname") || '';
firstname = firstname && firstname.substring(0, 1);
class Chat extends Component {
  constructor(props) {
    super(props);

    this.WEB_SOCKET_TIMEOUT_MS = 10000;
    this.baseHref = config.BASE_HREF;
    this.state = {
      message: '',
      messages: [],
      connection: null,
      showPopup: false,
      isOpen: false,
      item: null,
      activeTab: 1,
      cuttentlyWatchingCount: 0,
      cuttentlyWatching:{},
      subClips:[],
      tabIndex:0,
      selectedFeed:'',
      creatingClip: false,
    }
    this.chatRef = React.createRef();
    this.messagesEndRef = React.createRef();
    this.closeShareModal = this.closeShareModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement('body');
    this.initChatConnection();
    this.props.chime.subscribeToRosterUpdate(this.rosterCallback);
  }
  rosterCallback = (newRoster) => {
    this.setState({ cuttentlyWatchingCount: Object.keys(newRoster).length, cuttentlyWatching:newRoster})
  }
  async initChatConnection() {
    const { Meeting, Attendee } = this.props.joinInfo;
    const messagingUrl = `${config.CHAT_WEBSOCKET}?MeetingId=${Meeting.MeetingId}&AttendeeId=${Attendee.AttendeeId}&JoinToken=${Attendee.JoinToken}`
    const connection = new ReconnectingPromisedWebSocket(
      messagingUrl,
      [],
      'arraybuffer',
      new DefaultPromisedWebSocketFactory(new DefaultDOMWebSocketFactory()),
      new FullJitterBackoff(1000, 0, 10000)
    );

    if (config.DEBUG) console.log(connection);

    await connection.open(this.WEB_SOCKET_TIMEOUT_MS);

    connection.addEventListener('message', event => {
      const messages = this.state.messages;
      const data = event.data.split('::');
      const username = data[0];
      const message = data.slice(1).join('::'); // in case the message contains the separator '::'
      console.log('raj message',message);
      switch (message) {
        case "pause":
          if(window.player){
            window.player.pause();
          }
            break;
        case "play":
          if(window.player){
            window.player.play();
          }
            break;
        default:
            break;
    }
      messages.push({
        timestamp: Date.now(),
        username,
        message
      });

      this.setState({ messages });
    });

    this.setState({ connection });
    // if (this.chatRef) {
    //   this.chatRef.current.focus();
    // }

  }


  scrollToBottom = () => {
    this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  handleChange = e => {
    this.setState({ message: e.target.value })
  }
  postMessage = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { message, connection } = this.state;
      const { username } = this.props;
      if (message) {
        const data = `{
          "message": "sendmessage",
          "data": "${username}::${message.replace(/\\/g, '\\\\').replace(/"/g, '\\"')}"
        }`;
        if (connection) {
          connection.send(data);

        }
        this.setState({ message: '' });
      }
  }
  handleKeyDown = (e) => {
    if (e.keyCode === 13) { // keyCode 13 is carriage return
      this.postMessage(e);
  }
  }

  handleRoomClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { title, item } = this.props;
    const link = `${window.location.origin}${window.location.pathname.replace('meeting', 'join')}?action=join&id=${item.event_id}&room=${title}`;
    if (config.DEBUG) console.log(link);
    this.copyTextToClipboard(encodeURI(link));
  }

  setShowPopup = () => {
    // show popup message
    this.setState({ showPopup: true });

    // hide popup message after 2 seconds
    setTimeout(() => {
      this.setState({ showPopup: false })
    }, 2000);
  }

  copyTextToClipboard = text => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
        .then(() => {
          this.setShowPopup();
          if (config.DEBUG) console.log('Room link copied to clipboard');
        }, (err) => {
          if (config.DEBUG) console.log('Could not copy text: ', err);
        });
    }
  }

  parseUrls = (userInput) => {
    var urlRegExp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g;
    let formattedMessage = userInput.replace(urlRegExp, (match) => {
      let formattedMatch = match;
      if (!match.startsWith('http')) {
        formattedMatch = `http://${match}`;
      }
      return `<a href=${formattedMatch} className="chat-line__link" target="_blank" rel="noopener noreferrer">${match}</a>`;
    });
    return formattedMessage;
  }

  renderMessages = () => {
    const { messages, cuttentlyWatchingCount } = this.state;
    return (

<div className="chat_block">
              <div className="activity_hed">
                <div className="hedText">
                  <h3>LIVE CHAT</h3>
                  <p className="flex_div"><span className="material-icons">person</span> {cuttentlyWatchingCount}</p>
                </div>

                <a className="wp_close" href="#!" onClick={e => this.changeTab(e, 1)}><span className="material-icons">  close</span></a>

              </div>
              <div className="activity_content">
                <ul>
                {messages.map((message, i) => {
        let formattedMessage = this.parseUrls(message.message);
        let timeStamp = moment(message.timestamp).format("hh:mm a");
        let name = message.username.substring(0, 1);
        return (
                  <li key={i}><div className="name_thumb">{name}</div>
                    <p>{timeStamp} <span> <b> {message.username}</b> {renderHtml(formattedMessage)}</span></p>
                  </li>
                )
              })
            }
                </ul>
              </div>
            </div>

     
    )
  }

  donation = () => {
    this.setState({ isOpen: true })
  }

  closeModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ isOpen: false })
  }

  onChangePrice(price, priceMethod) {
    let self = this;
    self.setState({ price, priceMethod })
  }
  onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value, price: value })

  }
  continue = (item) => {
    let self = this;
    localStorage.setItem("e", "event");
    localStorage.setItem("eventId", item && item.event_id);
    localStorage.setItem("assetPrice", self.state.price);
    //  browserHistory.push("/paymentdonation?planId=true");
    this.props.history.push(`${this.baseHref}/paymentdonation?planId=true`);
  }
  onLikeClick(e, asset) {
    e.preventDefault();
    e.stopPropagation();
    if (asset.rating === "approve")
      asset["rating"] = "none"
    else
      asset["rating"] = "approve"
    this.setState({ item: asset })
    let approvebody = {
      "userassetType": 'event',
      "assetid": asset.event_id,
      "activity": "rating",
      "rating": asset["rating"]
    }
    this.props.approvedAsset(approvebody);
  }
  onDisLikeClick(e, asset) {
    e.preventDefault();
    e.stopPropagation();
    if (asset.rating === "disapprove")
      asset["rating"] = "none"
    else
      asset["rating"] = "disapprove"
    this.setState({ item: asset })
    let approvebody = {
      "userassetType": 'event',
      "assetid": asset.event_id,
      "activity": "rating",
      "rating": asset["rating"]
    }
    this.props.approvedAsset(approvebody);
  }
  componentDidUpdate(prevProps) {
    var self = this;

    if (prevProps.approvedProps && prevProps.approvedProps !== self.props.approvedProps) {
      if (self.props.approvedProps && self.props.approvedProps.userAsset) {
        let assetData = self.props.approvedProps;
        let asset = { ...self.props.asset }
        if (asset.assetid === assetData.userAsset.assetid) {
          self.setState(prevState => ({
            asset: {                   // object that we want to update
              ...prevState.asset,    // keep all other key-value pairs
              rating: assetData.userAsset.rating,
              approveCount: assetData.approveCount,
              disApproveCount: assetData.disApproveCount
            }
          }))
        }
      }
    }
  }
  changeTab(e, tab){
    let self = this;
    e.preventDefault();
    e.stopPropagation();
    self.setState({activeTab:tab},() =>{
      if(tab === 4 && this.state.tabIndex === 1){
        self.setupSubclips();
      }
    });
  }
  openModal(e) {
    e.preventDefault();
    e.stopPropagation();
    let self = this;
    self.setState({ shareClickPopup: true });
  }

  closeShareModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let self = this;
    self.setState({ shareClickPopup: false });
  }
  createClip(e){
    let self = this;
    self.setState({creatingClip:true});
    e.preventDefault();
    e.stopPropagation();
    console.log('window.videoStreamURL',window.videoStreamURL);
    let playbackUrl = window.videoStreamURL;
    let clipEndpoint = 'https://media-utility-api.dev.envoi.cloud/clip?channel='+ playbackUrl;
    const url = clipEndpoint;
console.log('url',url);
let subClips = this.state.subClips
      var call = new XMLHttpRequest();
      call.responseType = "json";
      call.open("GET", url, true);
      call.onload = function () {
        self.setState({creatingClip:false});
        let clipSrc = call.response.URL;
        console.log('clipSrc',clipSrc);
        if(clipSrc){
          let clip = {clipSrc}
          subClips.push(clip);
          self.setState({subClips}, () => {
            self.setState({tabIndex:1}, () => {self.setupSubclips();});
           
          })
        }
        
        //displayClip(clipSrc);
      };
      call.send("null");
  }
setupSubclips(){
  this.state.subClips.forEach(
    (clip,i) => {
      console.log('clip here', clip,i);
      let container = document.getElementById('video-player' + (i + 1));
      console.log('container here', container);
      if (!container) {
        console.log('no container found');
        return;
      }
      console.log('in container');

      let bitmovinPlayerKey = config.DEFAULT_BITMOVIN_PLAYER_KEY;
      let bitmovinAnalyticsKey = config.DEFAULT_BITMOVIN_ANALYTICS_KEY;

      let playerConfig = {
        key: bitmovinPlayerKey,
        analytics: { },
        playback: {
          autoplay: true,
          muted: true,
        },
      };

      if (bitmovinAnalyticsKey) {
        playerConfig.analytics.key = bitmovinAnalyticsKey
      }

      const bitmovin = window.bitmovin;
      let player = new bitmovin.player.Player(container, playerConfig);
      let videoStreamType = clip.clipSrc.endsWith('.m3u8')
        ? 'hls'
        : 'progressive';
      let playerSource = {
        [videoStreamType]: clip.clipSrc,
      };
      console.log('playerSource', playerSource);
      player.load(playerSource).then(() => {
        console.log('Successfully loaded source');
      }, () => {
        console.log('Error while loading source');
      });

    }
  );
  
}
setTabIndex(index){
  let self = this;
  self.setState({tabIndex:index},() => { if(index === 1){
    self.setupSubclips();
  }
  }); 
  
}
feedChange(e){
  window.videoStreamURL = this.props.eventData.playerUrls[e.target.value][0].hls;
  this.setState({selectedFeed:e.target.value});
}
  render() {
    let self = this;
    const { message, isOpen, priceMethod, otherPrice, activeTab, cuttentlyWatchingCount, cuttentlyWatching, subClips, tabIndex, creatingClip} = this.state;
    const { item } = this.props;
    // const popup = showPopup ? 'show' : '';
    let posterUrl = item && item.portrait ? item.portrait : item.images && item.images.length > 0 && item.images[0] + window.imageSuffix;
    let websiteLink = item && item.donation && item.donation.website;
    let classLikeButton = item && item.rating === "approve" ? "flex_div active" : "flex_div";
    let classDisLikeButton = item && item.rating === "disapprove" ? "flex_div active" : "flex_div";
    let info = this.props.eventData && this.props.eventData.playerUrls && this.props.eventData.playerUrls.length > 0 && this.props.eventData.playerUrls[0];
    if(info && info.duration){
      var formatted = moment.utc(info.duration*1000).format('HH:mm:ss');
    }
    return (
      <div className="chat full-height pos-relative">
        {/* <div className="chat__room-link full-width">
          <button className="room-link popup" onClick={this.handleRoomClick}>
            <span className="room-link__label">{this.props.title}</span>
            <svg className="room-link__svg" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.25033 9.99999C3.25033 8.57499 4.40866 7.41666 5.83366 7.41666H9.16699V5.83333H5.83366C3.53366 5.83333 1.66699 7.69999 1.66699 9.99999C1.66699 12.3 3.53366 14.1667 5.83366 14.1667H9.16699V12.5833H5.83366C4.40866 12.5833 3.25033 11.425 3.25033 9.99999ZM6.66699 10.8333H13.3337V9.16666H6.66699V10.8333ZM14.167 5.83333H10.8337V7.41666H14.167C15.592 7.41666 16.7503 8.57499 16.7503 9.99999C16.7503 11.425 15.592 12.5833 14.167 12.5833H10.8337V14.1667H14.167C16.467 14.1667 18.3337 12.3 18.3337 9.99999C18.3337 7.69999 16.467 5.83333 14.167 5.83333Z" fill="white" /></svg>
            <span className={`popuptext ${popup}`} id="myPopup">Watch Party Invite link Copied.</span>
          </button>
          <button className="room-link popup" onClick={this.donation}>
            <a href="javascript:void(0)" ><i className="material-icons mr-0">monetization_on</i></a>

          </button>
        </div>
        <div className="chat__wrapper full-width pos-relative">
          <div className="messages pd-x-1 pos-absolute">
            {this.renderMessages()}
            <div ref={this.messagesEndRef} />
          </div>
        </div> */}

        <div className="chat_header">

          <div className="chat_actions">
          {item.eventRating && item.eventRating.allow &&
            <div className="chat_button">
              <a className={classLikeButton} href="#!" onClick={e => self.onLikeClick(e, item)}><i className="material-icons ">thumb_up</i></a>
            
            </div>}
            {item.eventRating && item.eventRating.allow && <div className="chat_button">
              <a className={classDisLikeButton} href="#!" onClick={e => self.onDisLikeClick(e, item)}><i className="material-icons ">thumb_down</i></a>
            
            </div>}
            <div className="chat_button" onClick={e => self.openModal(e)}>
              <a className={'flex_div'} href="#!" ><i className="material-icons ">share</i></a>
            Share
            </div>
            <div className="chat_button creating_clips">
              <a href="#!" onClick={e => self.changeTab(e, 4)} className={activeTab === 4 ? 'flex_div active' : 'flex_div'} ><i className="material-icons ">content_cut</i></a>
            Create Clips
            </div>
            <div className="chat_button" onClick={e => self.changeTab(e, 2)}>
              <a href="#!" className={activeTab === 2 ? 'flex_div active' : 'flex_div'} ><i className="material-icons ">forum</i></a>
            Chat
            </div>
            <div className="chat_button activity_tab" onClick={e => self.changeTab(e, 3)}>
              <a href="#!" className={activeTab === 3 ? 'flex_div active' : 'flex_div'} ><i className="material-icons ">list_alt</i></a>
            Activity
            </div>

            {/* <button className="room-link popup room-url" onClick={this.handleRoomClick}>
            <p className="room-link__label">{this.props.title}</p>
            <svg className="room-link__svg" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.25033 9.99999C3.25033 8.57499 4.40866 7.41666 5.83366 7.41666H9.16699V5.83333H5.83366C3.53366 5.83333 1.66699 7.69999 1.66699 9.99999C1.66699 12.3 3.53366 14.1667 5.83366 14.1667H9.16699V12.5833H5.83366C4.40866 12.5833 3.25033 11.425 3.25033 9.99999ZM6.66699 10.8333H13.3337V9.16666H6.66699V10.8333ZM14.167 5.83333H10.8337V7.41666H14.167C15.592 7.41666 16.7503 8.57499 16.7503 9.99999C16.7503 11.425 15.592 12.5833 14.167 12.5833H10.8337V14.1667H14.167C16.467 14.1667 18.3337 12.3 18.3337 9.99999C18.3337 7.69999 16.467 5.83333 14.167 5.83333Z" fill="white" /></svg>
            <span className={`popuptext ${popup}`} id="myPopup">Watch Party Invite link Copied.</span>
          </button> */}

            {item && item.donation && item.donation.allow &&
              <button className=" donate" onClick={this.donation}>
                <i className="material-icons ml-2">volunteer_activism</i> DONATE
            </button>
            }
          </div>
        </div>
        {/* <Tabs className="chatpropstabs">
          <TabList>
            <Tab>Chat</Tab>
            <Tab>People</Tab>
          </TabList>
          <TabPanel>
            <p>
              <b>Chat</b>
            </p>
          </TabPanel>
          <TabPanel>
            <p>
              <b>People</b>
            </p>
          </TabPanel>
        </Tabs> */}
        <div className="chat__wrapper full-width pos-relative">
          <div className="messages pos-absolute">
           {activeTab === 1 &&<div className="chat_info">
              <h2>{item.title}</h2>
              <p className="sub_hed"><span>{info && info.releasedate && info.releasedate +' |'} </span> <span>{formatted && formatted +' |'}</span> </p>
              <p>{info && info.description}</p>
            </div>}
            {activeTab === 3 &&
            <div className="activity">
              <div className="activity_hed">
                <div className="hedText">
                  <h3>FEED ACTIVITY</h3>
                  <p className="flex_div"><span className="material-icons">visibility</span> {cuttentlyWatchingCount} Currently watching</p>
                </div>

                <a href="#!" onClick={e => self.changeTab(e, 1)} className="wp_close"><span className="material-icons">  close</span></a>

              </div>
              <div className="activity_content">
                <ul>
                 {Object.entries(cuttentlyWatching).map(([key, val], i) => {
                   let name = val.name.substring(0, 1);
                   return (<li key={i}><div className="name_thumb">{name}</div>
                    <p><span> {val.name} joined the party </span></p>
                   </li>)})}
                  {/* <li><img src="http://dev.sanchaninfo.com/wp-content/uploads/2020/01/team8.jpg" />
                    <p>6:13 PM <span> Mark joined the party </span></p>
                  </li> */}
                  
                </ul>
              </div>
            </div>
            }
            {activeTab === 2 && this.renderMessages()}
            {activeTab === 4 && <div>
             <div className="activity">
                <div className="activity_hed">
                  <div className="hedText">
                    <h3>CLIPS</h3>
                  </div>
                  <a onClick={e => self.changeTab(e, 1)} className="wp_close" href="#!"><span className="material-icons">  close</span></a>
                </div>
                <div className="activity_content">
                  <Tabs className="clips_tabs" onSelect={index => this.setTabIndex(index)} selectedIndex={tabIndex}>
                    <TabList>
                      <Tab>Add</Tab>
                      <Tab>List</Tab>
                    </TabList>
                    <TabPanel>
                      <div className="add_clips">
                        {this.props.eventData && this.props.eventData.playerUrls && this.props.eventData.playerUrls.playlistType == 'Multiplex' && <div><h6>Feed</h6>
                          <select value={this.state.selectedFeed} name="feed" className="" onChange={e => this.feedChange(e)}>
                            <option value={''}>Select feed</option>
                            {Object.keys(this.props.eventData.playerUrls).length > 0 &&
                              Object.keys(this.props.eventData.playerUrls).map((feed, i) => {
                                if (feed != 'playlistType') {
                                  return (
                                    <option key={i} value={feed}>{feed}</option>
                                  )
                                }

                              }
                              )}
                          </select></div>}
                        
                        <h6>Title</h6>
                        <input type="text" placeholder="Name"/>
                        <h6>Start Time</h6>
                        <input type="text" placeholder="Name"/>
                        <h6>Duration</h6>
                        <input type="text" placeholder="Name"/>
                        <h6>End Time</h6>
                        <input type="text" placeholder="Name"/>
                        <div className="btns_group mt-3">
                        {!creatingClip &&<button disabled={this.props.eventData.playerUrls.playlistType == 'Multiplex' && !this.state.selectedFeed} onClick={e => self.createClip(e)} className="color_btns mr-3">Save</button>}
                        {creatingClip && <button class="color_btns mr-3">
                            <i class="fa fa-spinner fa-spin"></i>Loading
                          </button>}
                          <button className="color_btns"> Reset</button>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      {subClips && subClips.length > 0 && subClips.map((clip, i) => {

                        return (
                          <div key={i} className="subclipplayer" id={"video-player" + (i + 1)} />
                        )
                      })
                      }
                    </TabPanel>
                  </Tabs>
                </div>
              </div> 
              </div>}
            <div ref={this.messagesEndRef} />
           
          </div>
        </div>
        {activeTab === 2 && <div className="chime-web-composer">
         <div className="chat_type">
           <div className="name_thumb">{firstname}</div>
           <a href="#!" onClick={(e) => this.postMessage(e)} className="post_btn">Post</a>
          <input
            ref={this.chatRef}
            className="chat_input"
            type="text"
            placeholder="Add a comment"
            value={message}
            maxLength={510}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
          />
          </div>
        </div>}
        <Modal
          isOpen={isOpen}
          style={customStyles}
          contentLabel="Example Modal"
          className="donate_popup">
          <div className="modal-head ">
            <h2>DONATE</h2>
            <a href="#!" onClick={e => self.closeModal(e)} className="dismiss_btn">
              <i className="material-icons">close</i>
            </a>
            <div className="donate-header">
              <div className="profile_block">
                <img alt="poster" src={posterUrl} />
              </div>
              <div className="header-desc">
                <h5>{item && item.donation && item.donation.title}</h5>
                {/* <p>{item && item.donation && item.donation.description}</p> */}
              </div>
            </div>
          </div>
          <div className="modal-body">
            <p className="don_by">Donation collected by
                        {websiteLink ?
                <a href={websiteLink} target="_blank">Website <i className="material-icons">open_in_new</i></a> : null}
            </p>
            <div className="don_block">
              <div className="don_list">
                {item && item.donation && item.donation.price && item.donation.price.length > 0 && item.donation.price.map(function (price, i) {
                  return (
                    <label key={i} className="radio_block">
                      <input type="radio" value={price} name="radio" onClick={e => self.onChangePrice(price, "price")} />
                      <span className="title">${price}</span>
                      <span className="checkblock"></span>
                    </label>
                  )
                })
                }
                <label className="radio_block">
                  <input type="radio" name="radio" value={self.state.price} onClick={e => self.onChangePrice(self.state.price, "other")} />
                  <span className="title">Other</span>
                  <span className="checkblock"></span>
                </label>
              </div>
              {priceMethod === "other" ?
                <div className="other_amnt">
                  <label>Other Amount</label>
                  <div className="enter_amnt">
                    <p>$</p>
                    <input type="text" name="otherPrice" value={otherPrice} onChange={e => self.onChange(e)} />
                  </div>
                </div> : null}
            </div>
            <div className="condition mt-4">
              <input type="checkbox" />
              <p>Show my name along with my donation amount in live chat Even if you don’t select this option, your donation amount (but no your name) may still be displayed during more popular live streams</p>
            </div>
          </div>
          <div className="modal-footer nobord nopad_tp">
            <button className="btn cont-btn canc-btn" onClick={e => self.closeModal()}>CANCEL</button>
            {self.state.price ?
              <button className="btn cont-btn" onClick={e => self.continue(item)}>CONTINUE</button> :
              <button className="btn cont-btn disabled" onClick={e => self.continue()}>CONTINUE</button>}
          </div>
        </Modal>
        <AssetInfoShare  item={this.props.item}
     title={this.props.title} closeModal={this.closeShareModal} shareClickPopup={this.state.shareClickPopup}  />
      </div>
    )
  }
}

Chat.propTypes = {
  chime: PropTypes.object,
  title: PropTypes.string,
  username: PropTypes.string,
  joinInfo: PropTypes.object,
  eventinfo: PropTypes.object,
  item: PropTypes.object,
};
const mapState = ({ approvedProps }) => ({ approvedProps });
const mapDispatch = { approvedAsset };
export default withRouter(connect(mapState, mapDispatch)(Chat));
// export default Chat;
