import React from 'react';

let imagesAssetResourcesPrefix = window.siteConfig && window.siteConfig.commonResourcesUrl + "screening/";
imagesAssetResourcesPrefix = imagesAssetResourcesPrefix ? imagesAssetResourcesPrefix :'https://envoi-common-resources.imgix.net/screening/';
const AlertPopup = (props) => (
    <div>
        <div className="novideo-alert" id="ErrorPopup" style={{ display: 'none' }}>
            <div className="alert-u">
                <p className="u-pop-icon"><img alt="warning" src={imagesAssetResourcesPrefix + "images/states_of_applications/warning.png"} /></p>
                <p>Profile Already Exists!</p>
            </div>
        </div>
        
        <div className="novideo-alert" id="SuccessAddPopup" style={{ display: 'none' }}>
            <div className="alert-u">
                <p className="u-pop-icon"><img alt="success" src={imagesAssetResourcesPrefix + "images/states_of_applications/success.png"} /></p>
                <p>Added successfully</p>
            </div>
        </div>
    </div>
)
export default AlertPopup;