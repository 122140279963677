import React from 'react';
const Loader = () => {
    
    return (
        <div id="overlay">
            <div className="preLoader">
                <div className="loftloader-wrapper pl-frame">
                    <div className="loader">
                        <div className="spark"></div>
                        <img alt="loader" src={window.preLoader} />
                        <span></span>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default (Loader);