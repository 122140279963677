import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import wp_img from '../../assets/formaria.png';
class EventInfo extends Component {
    
    
    render() {
        
        
        return (
            <div className="common_container darkbluebg">
            <a href="#!" onClick={ev => {ev.preventDefault();}} className="round_backbtn pos_50"><i className="material-icons">keyboard_arrow_left</i></a>
            <div className="preview_wrapper">
              <div className="preview_body">
                <div className="preview_img">
                  <h1>Watch Party</h1>
                  <img alt="img" src={wp_img} />
                </div>
                <div className="preview_details">
                  <div className="asset_views flex_display">
                    <p className="flex_display"><i className="material-icons mr-2">visibility</i>209 Watching</p>
                    <ul className="flex_display">
                      <li className="flex_display mr-3"><i className="material-icons mr-2">thumb_up</i>1k</li>
                      <li className="flex_display"><i className="material-icons mr-2">thumb_down</i>98</li>
                    </ul>
                  </div>
                  <h1 className="room_title">For Maria</h1>
                  <ul className="flex_display room_features">
                    <li>2021</li>
                    <li>1h 52m</li>
                    <li>Feature Narrative</li>
                  </ul>
                  <p className="room_desc">Lorem ipsum dolor sit amet, consectetur vadipiscing elit, sed do eiusmod tempor
  incididunt ut labore et dolore magna aliqua.</p>
                  <a className="flex_display fillbig_btn blue_bg" href="#!" onClick={ev => {ev.preventDefault();}}>
                    <i className="material-icons mr-2">play_arrow</i>Join</a>
                </div>
              </div>
              <div className="preview_footer">
                <a className="stroke_btn" href="#!" onClick={ev => {ev.preventDefault();}}>Leave watch party</a>
              </div>
            </div>
          </div> 
        )
    }
}

export default withRouter(EventInfo);