import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as config from '../config';
class Success extends Component {
    constructor(props) {
        super(props);
        this.baseHref = config.BASE_HREF;
        this.state = {
            time: {},
            seconds: 2,
            route: window.defaultRoute
        };
        this.loadSiteConfig = this.loadSiteConfig.bind(this);
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }
    componentDidMount() {
        var self = this;
        self.loadSiteConfig();
        self.startTimer();

        // if (window.siteConfig && window.siteConfig.segment && window.siteConfig.segment.enable) {
        //     let options = {
        //         "pageName": this.constructor.name,
        //         "appname": window.appname,
        //         "path": window.location.pathname,
        //         "search": window.location.search,
        //         "referrer": window.location.href,
        //         "url": window.location.href,
        //         "UserEmail": localStorage.getItem("email"),
        //         "userid": localStorage.getItem("userid"),
        //     }
        //     window.analytics.page(options);
        //     if (window.siteConfig.google && window.siteConfig.google.enable) {
        //         ReactGA.initialize(window.siteConfig.google.key);
        //         ReactGA.pageview(window.location.pathname + window.location.search);
        //     }
        // }
    }
    

    loadSiteConfig() {
        let self = this;
        let configinterval = setInterval(function () {
            if (window.siteConfig && window.siteConfig.appResourcesUrl) {
                window.logoImage = window.imagesPath + "screening/images/headerlogo.png" + window.imageSuffix;
                window.preLoader = window.imagesPath + 'screening/images/window.preLoader.png';
                self.setState({ logoImage: window.logoImage, preLoader: window.preLoader });
                clearInterval(configinterval);
            }

        }, 1000);
    }
    click(e) {
        let assettype = localStorage.getItem("a");
       // localStorage.removeItem("a");
        
        let assetinfo = JSON.parse(localStorage.getItem('eventInfo'));
        // let playlistType = localStorage.getItem("playlistType") ? localStorage.getItem("playlistType"):'playlistbytag';
        // playlistType = playlistType !== 'undefined' ? playlistType : 'playlistbytag';
        if(assettype === 'event'){
            this.props.history.push(`/meeting?room=${assetinfo.title}`);
        }
        // return
        //  if (actionType == "rent" && assettype == "channelasset" && (playlistType != 'multiCam' && playlistType != 'Multiplex')) {
        //     this.props.history.push("/player/channel/" + code);
        // }
        // else if ((assettype == "asset" || assettype == "gift" || actionType == "rent") && assetId != null) {
        //     this.props.history.push("/player/asset/" + assetId);
        // } else if ((assettype == "channelasset" || code && code != "undefined") && (playlistType != 'multiCam' && playlistType != 'Multiplex')) {
        //     this.props.history.push("/player/channel/" + code);
        // } else if((assettype == "channelasset" || code && code != "undefined") && (playlistType == 'multiCam' || playlistType == 'Multiplex')){
        //     this.props.history.push('/players/' + code + '/' + playlistType);
        // }else if (assetId && assetId != "undefined" && assetId != null) {
        //     this.props.history.push("/player/asset/" + assetId);
        // } else {
        //     if (actualRoute && actualRoute != null)
        //         this.props.history.push("/" + actualRoute);
        //     else
        //         this.props.history.push("/" + window.defaultRoute);
        // }
    }
    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }
    startTimer() {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        let self = this;
        // Remove one second, set state so a re-render happens.
        let seconds = self.state.seconds - 1;
        self.setState({
            time: self.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds === 0) {
            clearInterval(self.timer);
            self.click()
        }
    }
    render() {
        // let assetID = localStorage.getItem("assetid");
        // let window.appname = localStorage.getItem("anw");
        // let type = localStorage.getItem("t");
        // let limitPlayback = localStorage.getItem("lp");
        // let URL = "/" + window.appname + "/" + type + "/" + assetID + (limitPlayback != "" && "?limitPlayback="+ limitPlayback)
        return (
            <div>
                <div className="subscrib_wrp">
                    <div className="subscrib_logo"><img alt="logo" src={this.state.logoImage} /></div>
                    <div className="subscrib_block">
                        <h4>Thank you for purchasing</h4>
                        {/* <div className="form-group">
                            <button onClick={e => this.click(e)} className="submit font-white-u">GO TO PLAY</button>
                        </div> */}
                        {/* <div className="timer">{this.state.time.s}</div> */}
                    </div>
                </div>

            </div>
        )
    }
}


const mapState = ({ webMenus }) => ({ webMenus });
const mapDispatch = {};
export default connect(mapState, mapDispatch)(Success);
