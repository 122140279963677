
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import '../userContext';
import { UserConsumer } from '../userContext';
import { Auth0Lock } from 'auth0-lock';
import { Auth0LockPasswordless } from 'auth0-lock';
import * as config from '../../config';
import axios from "axios";
function location(name) {
    let results = new RegExp(name + '=([^&#]*)').exec(window.location.href);
    if (results == null) {
        results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    }
    if (results == null) {
        return null;
    }
    return decodeURI(results[1]) || 0;
}
let localStorage = window.localStorage || sessionStorage;
let clientId, domain, enablePasswordLess, oktaLogin;
// let oktaclientId, oktadomain;
let cognitoLogin, cognitoClientId, loginCognitoUrl, cognitoTokenType;

// var signupLock;
let oktaWidget;

// Authentication
let auth0Logo = window.imagePrefix + "screening/images/landingpagelogo.png" + window.imageSuffix;
//let lambdaUrl = "https://c3s0jv43fe.execute-api.us-east-1.amazonaws.com/latest";
// let lambdaUrl = "https://content-screening-api.dev.envoi.cloud";
let lambdaUrl=window.lambdaUrl;
let locationURL = window.location.protocol + "//" + window.location.host + "/";


class auth0signin extends Component {

    constructor(props) {
        super(props);
        this.baseHref = config.BASE_HREF;
        let value = this.context;
        let self = this;
        let siteConfig = (value && value.siteConfig) || {};
        let clientId = ((siteConfig && siteConfig.authentication && siteConfig.authentication.auth0) || {}).authClientId || ""; //Auth0 clientId
        let domain = ((siteConfig && siteConfig.authentication && siteConfig.authentication.auth0) || {}).authClientDomain || "";
        if (value && value.siteConfig && value.siteConfig.cognitoLogin) {
            let url;
            let cognitotoken = location("id_token");
            if (cognitotoken)
                url = lambdaUrl + '/authentication?appname=' + value.appname + '&token=' + cognitotoken + '&env=' + value.env + '&host=' + locationURL;

            if (cognitotoken) {
                this.authenticationMethod(url);
            }
        }
        else if (siteConfig && siteConfig.authentication && siteConfig.authentication.key === "auth0" && clientId && domain) {
            var defaultOptions = {
                configurationBaseUrl: 'https://cdn.auth0.com',
                auth: {
                    responseType: 'token id_token',
                }
            };
            window.signinLock = new Auth0Lock(clientId, domain, defaultOptions);
            window.signinLock.on("authenticated", function (authResult) {
                $("#overlay").removeClass("hidediv");
                $(".landing_wrap").removeClass("hidediv");
                window.signinLock.hide();
                window.signinLock.getUserInfo(authResult.accessToken, function (error, profile) {
                    if (error) {
                        console.log('authoerror', error)
                        return;
                    }
                    var tokenval = authResult.idToken;
                    let url = lambdaUrl + '/authentication?appname=' + value.appname + '&token=' + tokenval + '&env=' + value.env + '&host=' + locationURL;
                    self.authenticationMethod(url);
                });
            });
        }

    }

    authenticationMethod(url) {
        let self = this;

        axios.post(url)
            .then(function (response) {
                if (response && response.data.statusCode === 200) {
                    let token = response.data.result.token;
                    window.token = token;
                    localStorage.setItem("token", response.data.result.token);
                    localStorage.setItem("fullName", response.data.result.firstname + " " + response.data.result.lastname);
                    localStorage.setItem("userImage", response.data.result.userimage);
                    localStorage.setItem("firstname", response.data.result.firstname);
                    localStorage.setItem("lastname", response.data.result.lastname);
                    localStorage.setItem("email", response.data.result.emailid);
                   
                    if (response.data.result && (response.data.result.status === "PENDING REGISTRATION" || response.data.result.status === "PENDING ADMIN APPROVAL") && !response.data.result.isVerified) {
                        self.props.history.push("/create");
                    }
                    // else if (response.data.result && response.data.result.status == "PENDING SUBSCRIPTION" && paymentEnable) {
                    //     self.props.history.push("/payment");
                    // } else if (response.data.result && response.data.result.status == "PENDING VERFICATION") {
                    //     self.props.history.push("/adddevice");
                    // }
                    else if (response.data.result) {
                        self.props.history.push("/home");
                    }
                    // window.location = '/home';
                    // this.props.history.push("/home");
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    componentDidUpdate(prevProps) {
        if (this.props.clickAccess) {
            this.loginLock()
        }
        if (prevProps.websiteDefaultData !== this.props.websiteDefaultData) {
            let siteConfig = this.props.websiteDefaultData.config || {};
            let imagesPath = siteConfig.appResourcesUrl + siteConfig.appResourcesPath;
            let imagePrefix = imagesPath;
            let imageSuffix = "?auto=compress,format";
            window.imagePrefix = imagePrefix;
            window.imageSuffix = imageSuffix;
            this.setState({ imagePrefix, imageSuffix })
        }
    }
    componentDidMount() {
        let url;
        let cognitotoken = location("id_token");
        if (cognitotoken)
            url = lambdaUrl + '/authentication?appname='+window.appname+'&token=' + cognitotoken + '&env=dev&host=' + locationURL;

        if (cognitotoken) {
            this.authenticationMethod(url);
        }
    }
    loginLock(e, configData) {
        e.stopPropagation();
        e.preventDefault();
        switch (configData && configData.siteConfig && configData.siteConfig.authentication && configData.siteConfig.authentication.key) {
            case "auth0":
                clientId = (configData.siteConfig.authentication.auth0 || {}).authClientId || ""; //Auth0 clientId
                domain = (configData.siteConfig.authentication.auth0 || {}).authClientDomain || "";
                enablePasswordLess = (configData.siteConfig.authentication.auth0 || {}).enablePasswordLess || false;
                oktaLogin = false;
                break;
            case "okta":
                // oktaclientId = (configData.siteConfig.authentication.okta || {}).clientId; // OKTA Client Id
                // oktadomain = (configData.siteConfig.authentication.okta || {}).domain;
                if (configData.siteConfig.authentication.key === "okta") {
                    oktaLogin = true;
                }
                break;
            case "cognito":
                cognitoLogin = true;
                cognitoClientId = (configData.siteConfig.authentication.cognito || {}).clientId; // Cognito Client Id
                loginCognitoUrl = (configData.siteConfig.authentication.cognito || {}).loginCognitoUrl;
                cognitoTokenType = (configData.siteConfig.authentication.cognito || {}).cognitoTokenType || "token";
                break;
            default:
                break;
        }
        if (configData && configData.siteConfig && configData.siteConfig.authentication && configData.siteConfig.authentication.key === "auth0" && clientId && domain) {
            auth0Logo = configData.imagePrefix + "screening/images/landingpagelogo.png";

            let defaultOptions = {
                container: 'logincontainer',
                configurationBaseUrl: 'https://cdn.auth0.com',
                auth: {
                    responseType: 'token id_token',
                },
                theme: {
                    logo: auth0Logo
                }
            };
            
            if (enablePasswordLess)
            window.signinLock = new Auth0LockPasswordless(clientId, domain, defaultOptions);
            else
            window.signinLock = new Auth0Lock(clientId, domain, defaultOptions);
            defaultOptions["container"] = 'signupcontainer';

            // if (enablePasswordLess)
            //     signupLock = new Auth0LockPasswordless(clientId, domain, defaultOptions);
            // else
            //     signupLock = new Auth0Lock(clientId, domain, defaultOptions);
        }
        //  else if (oktaLogin && oktadomain && oktaclientId) {

        //     oktaWidget = new OktaSignIn({
        //         logo: logoImage,
        //         el: '#osw-container',
        //         baseUrl: oktadomain, // https://dev-852395.oktapreview.com  https://sanchaninfo.okta.com
        //         clientId: oktaclientId,
        //         redirectUri: window.location.protocol + "//" + window.location.host + '/',
        //         authParams: {
        //             issuer: oktadomain,
        //             responseType: ['id_token', 'token']
        //         },
        //         scope: 'openid profile email'
        //     });
        // }
        e && e.preventDefault();

        localStorage.setItem("showDiv", this.props.hiddenDiv)
        localStorage.setItem("removeHidden", this.props.removeHidden)

        if (configData && configData.siteConfig && configData.siteConfig.authentication && configData.siteConfig.authentication.key === "auth0") {
            $("." + this.props.hiddenDiv).hide();
            $("#" + this.props.removeHidden).removeClass("hidediv");
            let options = {
                languageDictionary: {
                    emailInputPlaceholder: "Enter Email",
                    passwordInputPlaceholder: "Enter Password",
                    loginTitle: 'Login',
                    loginAtLabel: 'Login',
                    loginSubmitLabel: 'Log in',
                    title: "Login with"
                },
                allowSignUp: false
            };
            if (enablePasswordLess)
            window.signinLock.show({ passwordlessMethod: 'link' }, options);
            else
            window.signinLock.show(options);
        } else if (cognitoLogin) {
            if (cognitoTokenType === "code")
                window.location = loginCognitoUrl + "?client_id=" + cognitoClientId + "&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=" + window.location.origin
            else
                window.location = loginCognitoUrl + "?client_id=" + cognitoClientId + "&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=" + window.location.origin
        } else if (oktaLogin) {
            $("." + this.props.hiddenDiv).hide();
            $("#" + this.props.removeHidden).removeClass("hidediv");
            oktaWidget.show();
        }
        //  else if (configData.siteConfig.authentication && configData.siteConfig.authentication.key == "novi") {
        //     let novibaseURL = configData.siteConfig.authentication.novi.baseUrl
        //     let noviClientId = configData.siteConfig.authentication.novi.clientId

        //     window.location = novibaseURL + "/oauth/authorize?client_id=" + noviClientId + "&scope=openid&response_type=code%20token%20id_token&redirect_uri=" + window.location.origin

        // }
    }

    render() {
        const { buttonHide, cssClass, labelText } = this.props
        return (
            !buttonHide ?
                <UserConsumer>
                    {
                        (data) => {
                            return <div>
                                {/* <div id="overlay" className="hidediv">
                                    <div className="preloader">
                                        <div className="loftloader-wrapper pl-frame">
                                            <div className="loader">
                                                <div className="spark"></div>
                                                <h1>testing.....</h1>
                                                <img src={} />
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                
                                <a style={{ "cursor": "pointer" }} href="#!" onClick={e => this.loginLock(e, data)} className={cssClass}>{labelText}</a></div>
                        }
                    }
                </UserConsumer>

                : null

        )
    }
}

// export default withRouter(auth0signin);

const mapState = ({ websiteDefaultData }) => ({ websiteDefaultData });
const mapDispatch = {};
export default withRouter(connect(mapState, mapDispatch)(auth0signin));