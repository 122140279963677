

import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getWebsiteDefault, getUser } from '../../redux/reducers/reducer';
import $ from 'jquery';
import SignIN from './Signin';
import SignUP from './signUP';
import { UserContext } from '../userContext';
class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = { userinfo: '' }

    }
    static contextType = UserContext;
    componentDidMount() {
        window.token = localStorage.getItem('token');
        if (window.token && window.token != null) {
            this.props.getUser(window.token);
        }
        if (window.siteConfig && window.siteConfig.analytics && window.siteConfig.analytics.segment && window.siteConfig.analytics.segment.enable) {
            let options = {
                "pageName": this.constructor.name,
                "appName": window.appname,
                "path": window.location.pathname,
                "search": window.location.search,
                "referrer": window.location.href,
                "url": window.location.href,
                "UserEmail": localStorage.getItem("email"),
            }
            window.analytics.page(options);
        }
    }
    componentDidUpdate(prevProps) {
        let self = this;
        if (prevProps.userinfo !== this.props.userinfo) {
            if (this.props.userinfo && this.props.userinfo.status === "PENDING REGISTRATION") {
                self.props.history.push("/create");
            } else if(window.token && window.token != null){
                self.props.history.push("/home");
            }
        }
    }

    closeLock(e) {
        
        let showDiv = localStorage.getItem("showDiv");
        let removeHidden = localStorage.getItem("removeHidden");

        $("#" + removeHidden).addClass("hidediv")
        $("." + showDiv).show();

        if (window.oktaLogin) {
            window.oktaWidget.hide();
        } else if (window.siteConfig.authentication && window.siteConfig.authentication.key === "auth0") {
            window.signinLock.hide();
            window.signupLock.hide();
        }
    }
    render() {
        let defaultbgimage = window.siteConfig && window.siteConfig.defaultLandscape;
        let bgimage;
        if(defaultbgimage){
            bgimage = {
                backgroundImage: "url(" +  defaultbgimage  + ")"
              };
        }
       
        return (
            <div className="jp_landing bg_img" style={bgimage}>
                <div className="overlay"></div>
                {/* <div id="signupcontainer">
                </div>
                <div id="logincontainer">
                </div> */}
                <div id="authlogindiv" className="steps hidediv">
                    <img alt="Auth0" src={window.auth0Logo} className="u-logo-img" />
                    <div className="stepdesign">
                        <div onClick={e => this.closeLock(e)} className="closebtn" >Close</div>
                    </div>
                    <div className="u-bl-center">
                    <div id="signupcontainer">
                </div>
                    <div id="logincontainer">
                </div>
                    </div>
                </div>
                <div className="jp_signinWrapper">
                    <img alt="header" src={window.imagesPath + "screening/images/headerlogo.png"} className="logo_white" />
                    <div className="jp_descBlock">
                        <h1>Join the Watch Party</h1>
                        <p>With Watch Party, chat and watch movies and TV shows with others</p>
                        <div className="poa_btns">
                            {/* <a href="#javascript" className="filled" >SIGN UP</a> */}
                            {/* <a href="#javascript" >SIGN IN</a> */}
                            <SignUP labelText="SIGN UP" cssClass="sign_btn filled" hiddenDiv="jp_signinWrapper" removeHidden="authlogindiv"  buttonHide={false} />
                            <SignIN labelText="SIGN IN"  cssClass="sign_btn" hiddenDiv="jp_signinWrapper" removeHidden="authlogindiv" buttonHide={false} />
                        </div>
                    </div>
                    <div className="store-icons">
                        <a href="#!" onClick={ev => {ev.preventDefault();}}><img alt="apple" src="https://envoi-common-resources.imgix.net/screening/images/common-images/applestore.png" />
                        </a>
                        <a href="#!" onClick={ev => {ev.preventDefault();}}><img alt="tv" src="https://envoi-common-resources.imgix.net/screening/images/common-images/amazonfiretv.png" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.paff.www" >
                            <img alt="google" src="https://envoi-common-resources.imgix.net/screening/images/common-images/google-play.png" />
                        </a>
                    </div>
                </div>
            </div>

        )

    }
}

// export default withRouter(LandingPage);
const mapState = ({ websiteDefaultData, userinfo }) => ({ websiteDefaultData, userinfo });
const mapDispatch = { getWebsiteDefault, getUser };
export default withRouter(connect(mapState, mapDispatch)(LandingPage));