import axios from "axios";
// import asyncLoop from "node-async-loop";
import myInitObject from '../../utils/utils';
import {
    GET_ASSET_TAGS,
    GET_ALL_PARTNERS,
    GET_REGIONS,
    BRAINTREE_CUSTOMER,
    GENARATE_ACTIVATION_CODE,
    GENARATE_USER_RELATION,
    GET_USER,
    ADD_PROFILE,
    GET_COUNTRIES,
    GET_ADDRESS,
    GET_EVENTS,
    APPROVED_ASSET,
    ITEMS_IS_LOADING,
    ASSET_PAYMENT,
    GET_ASSETINFO,
    GET_EVENTINFO,

} from './ActionTypes'

import {
    websiteDefaults,
    itemsIsLoading,
    activationCodeProps,
    Getpartners,
    userinfo,
    dispatch_addprofile,
    dispatch_countries,
    regions,
    dispatch_address,
    braintreeCustomer,
    genarateActivationCodeProps,
    genarateUserRelationProps,
    dispatch_events,
    approvedProps,
    assetPayment,
    assetinfo,
    eventinfo,
} from './Actions'
import $ from 'jquery';

//initiate your starting state
let initial = {
    websiteDefaultData: [],
    Getpartners: [],
    userinfo: [],
    regions: [],
    braintreeCustomer: [],
    genarateActivationCodeProps: [],
    UserRelationData: {},
    events: [],
    approvedProps: [],
    loading: false,
    assetPayment: [],
    assetinfo: [],
    eventinfo: [],

};

let assetsCount = 12;
let pageNumber = 1;
let scrubbing = true;
let siteConfig;
/////////////////CONSTANTS/////////////////////
let appname = window.appname;
// let lambdaUrl = "https://c3s0jv43fe.execute-api.us-east-1.amazonaws.com/latest";
// let lambdaUrl = "https://content-screening-api.dev.envoi.cloud";
let lambdaUrl = window.lambdaUrl;
// let commonApi=window.commonApi;
let environmentVariable = 'dev';
let token = localStorage.getItem('token');

const getQuery = appname ? 'appname=' + appname : 'location=' + window.location.href;
 window.paymentEnable = ((window.siteConfig && window.siteConfig.subscription) || {}).enable || false;
 window.requiredSelectDevice = window.siteConfig && window.siteConfig.requiredSelectDevice;
 let commonApi = window.siteConfig && window.siteConfig.commonApi;
/////////////////REDUCER/////////////////////





const reducer = (state = initial, action) => {
    switch (action.type) {
        case GET_ASSET_TAGS:
            return Object.assign({}, state, { websiteDefaultData: action.websiteDefaultData });
        case GET_ALL_PARTNERS:
            return Object.assign({}, state, { Getpartners: action.partners });
        case GET_REGIONS:
            return Object.assign({}, state, { regions: action.regionsState });
        case BRAINTREE_CUSTOMER:
            return Object.assign({}, state, { braintreeCustomer: action.braintreeCustomerState });
        case GENARATE_ACTIVATION_CODE:
            return Object.assign({}, state, { genarateActivationCodeProps: action.genarateActivationCodeState });
        case GENARATE_USER_RELATION:
            return Object.assign({}, state, { UserRelationData: action.UserRelationData });
        case GET_USER:
            return Object.assign({}, state, { userinfo: action.user });
        case ADD_PROFILE:
            return Object.assign({}, state, { addingprofile: action.addprofile });
        case GET_COUNTRIES:
            return Object.assign({}, state, { countries: action.countries });
        case GET_ADDRESS:
            return Object.assign({}, state, { address: action.address });
        case GET_EVENTS:
            return Object.assign({}, state, { events: action.events });
        case APPROVED_ASSET:
            return Object.assign({}, state, { approvedProps: action.approvedState })
        case ITEMS_IS_LOADING:
            return Object.assign({}, state, { loading: action.loading });
        case ASSET_PAYMENT:
            return Object.assign({}, state, { assetPayment: action.asset });
        case GET_ASSETINFO:
            return Object.assign({}, state, { assetinfo: action.asset });
        case GET_EVENTINFO:
            return Object.assign({}, state, { eventinfo: action.event });
        default:
            return state;
    }
};

export default reducer;

// Price Format
export const priceFormat = (value, currency, countryCode) => dispatch => {
    const formatter = new Intl.NumberFormat(countryCode, {
        style: 'currency',
        currency: currency,
    });

    return formatter.format(value);
}

/////////////// ACTION GETASSET_TAGS///////////////////
export const getWebsiteDefault = () => dispatch => {
    dispatch(itemsIsLoading(true));
    // if (mediaServicesApiAppName)
    axios.get(lambdaUrl + "/websitedefaults?" + getQuery)
        .then((response) => {
            let siteConfigresult = response.data.result.config || {};
            siteConfig = siteConfigresult;
            window.siteConfig = siteConfig;
            if (siteConfig) {

                myInitObject.siteConfig = siteConfig;
                Object.freeze(myInitObject)
                window.paymentEnable = (siteConfig.subscription || {}).enable || false;
                window.requiredSelectDevice = siteConfig.requiredSelectDevice;
                commonApi = siteConfig.commonApi;
                // console.log('paymentEnable', paymentEnable, requiredSelectDevice, commonApi)
            }

            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((websiteDefaultData) => {
            dispatch(websiteDefaults(websiteDefaultData))

        })
        .catch((err) => {
            dispatch(websiteDefaults([]))
            console.error.bind("getpairdevice reducer.js error", err);
        })
};

export function activationCode(bodydata) {
    token = localStorage.getItem('token');
    return (dispatch) => {
        let url = lambdaUrl + '/activationCode?appname=' + appname + '&env=' + environmentVariable + '&token=' + token + '&pairingcode=' + bodydata.pairingcode;
        if (bodydata.userid)
            url += "&uid=" + bodydata.userid

        axios.post(url, { type: 'application/json' })
            .then((response) => {
                if (response.data.statusCode === 401) {
                    return response.data;
                } else {
                    return response.data.result;
                }
            })
            .then((activationCodeState) => {
                dispatch(activationCodeProps(activationCodeState))
            })
            .catch((err) => {
                dispatch(activationCodeProps(err.response.data));
                console.error.bind("address_result reducer.js error", err);
            })
    };
}


export const getArtists = (menu, creatorType) => dispatch => {
    dispatch(itemsIsLoading(true));
    token = localStorage.getItem('token');
    let url = lambdaUrl + '/getArtists?appname=' + appname + '&env=' + environmentVariable + "&token=" + token + "&menu=" + menu + '&assetcount=' + assetsCount + '&pageNumber=' + pageNumber + '&scrubbing=' + scrubbing;

    if (creatorType) {
        url += "&creatorType=" + creatorType + '&scrubbing=' + scrubbing
    }
    axios.get(url)
        .then((response) => {
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((partners) => {
            dispatch(Getpartners(partners))
        })
        .catch((err) => {
            console.error.bind("getArtists reducer.js error", err);
        })
};

export const getUser = (token) => dispatch => {
    dispatch(itemsIsLoading(true));
    axios.post(lambdaUrl + '/getuser?appname=' + appname + '&env=' + environmentVariable + '&token=' + token)
        .then((response) => {
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((user) => {
            dispatch(userinfo(user))
        })
        .catch((err) => {
            console.error.bind("getUser reducer.js error", err);
        })
};

export const customerAddress = (formData, id) => dispatch => {
    dispatch(itemsIsLoading(true));
    token = localStorage.getItem('token');
    axios.post(lambdaUrl + '/address?token=' + token + '&env=' + environmentVariable + '&appname=' + appname + '&id=' + id, formData, { type: 'application/json' })
        .then((response) => {

            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .catch((err) => {
            console.error.bind("customerAddress reducer.js error", err);
        })
};

export const updateUserRelation = (formData) => dispatch => {
    
    dispatch(itemsIsLoading(true));
    token = localStorage.getItem('token');
    axios.post(lambdaUrl + '/partnerDetails?token=' + token + '&env=' + environmentVariable + '&appname=' + appname, formData, { type: 'application/json' })
        .then((response) => {
            // if (window.siteConfig.addcardEnable) {
            //     this.props.history.push("/addcard");
            // } else if (paymentEnable == true && creatorpromolink && creatorpromocode) {
            //     window.location = "/payment?planId=" + creatorpromolink;
            // } else if (paymentEnable == true) {
            //     this.props.history.push("/payment");
            // } else if (requiredSelectDevice == true) {
            //     this.props.history.push("/adddevice");
            // } else {
            //     this.props.history.push("/activation");
            // }
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((UserRelationData) => {
            dispatch(genarateUserRelationProps(UserRelationData))
        })
        .catch((err) => {
            console.error.bind("updateUserRelation reducer.js error", err);
        })
};

export function addProfiles(name, profileImage) {
    return (dispatch) => {

        var createProfile = { "profileName": name, "profileImage": profileImage }

        axios.post(lambdaUrl + '/createProfile?appname=' + appname + '&env=' + environmentVariable + '&token=' + token, createProfile, { type: 'application/json' })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    $(document).ready(function () {
                        $('#SuccessAddPopup').css("display", "block");
                        setTimeout(function () { $('#SuccessAddPopup').css('display', 'none'); }, 2000);
                    });
                }
                else if (response.data.statusCode === 401) {
                    $(document).ready(function () {
                        if (response.data.error && response.data.error === 'profileName exists') {
                            $('#ErrorPopup').css("display", "block");
                            setTimeout(function () { $('#ErrorPopup').css('display', 'none'); }, 2000);
                        }

                    });
                }

                return response.data.result;
            })
            .then((addprofile) => {
                dispatch(dispatch_addprofile(addprofile))
            })
            .catch((err) => {
                console.error.bind("addProfiles reducer.js error", err);
            })
    };
}

export function getCountries(token) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        axios.post(commonApi + '/getcountries?&appname=' + appname)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                return response.data.result;
            })
            .then((countries_result) => {
                dispatch(dispatch_countries(countries_result))
            })
            .catch((err) => {
                console.error.bind("countries_result reducer.js error", err);
            })
    };
}

export function getRegionsAPI(alpha3) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        axios.post(commonApi + '/getregions?&alpha3=' + alpha3)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                return response.data.result;
            })
            .then((regionsState) => {
                dispatch(regions(regionsState))
            })
            .catch((err) => {
                console.error.bind("regionResult reducer.js error", err);
            })
    };
}

export function getAddress(addresstype) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        token = localStorage.getItem('token');
        axios.post(lambdaUrl + '/getaddress?&appname=' + appname + '&env=' + environmentVariable + '&token=' + token + '&addresstype=' + addresstype)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                return response.data.result;
            })
            .then((address_result) => {
                dispatch(dispatch_address(address_result))
            })
            .catch((err) => {
                console.error.bind("address_result reducer.js error", err);
            })
    };
}

export const addCustomerInBrainTree = () => dispatch => {
    token = localStorage.getItem('token');
    axios.post(lambdaUrl + '/addcard?token=' + token + '&appname=' + appname + '&env=' + environmentVariable + "&createCustomer=true")
        .then((response) => {
            return response.data.result;
        })
        .then((braintreeCustomerState) => {
            dispatch(braintreeCustomer(braintreeCustomerState))
        })
        .catch((err) => {
            console.error.bind("updateUser reducer.js error", err);
        })
};

export function genarateActivationCode(device) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        token = localStorage.getItem('token');
        axios.post(lambdaUrl + '/genarateActivationCode?appname=' + appname + '&env=' + environmentVariable + '&token=' + token + '&device=' + device)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.statusCode === 401)
                    return response.data;
                else
                    return response.data.result;
            })
            .then((genarateActivationCodeState) => {
                dispatch(genarateActivationCodeProps(genarateActivationCodeState))
            })
            .catch((err) => {
                dispatch(genarateActivationCodeProps(err.response.data));
                console.log("address_result reducer.js error", err.response);
            })
    };
}

export function getEvents(eventid, eventType) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        let token = localStorage.getItem('token');
        let url = lambdaUrl + '/getEvents?appname=' + appname + '&env=' + environmentVariable + '&token=' + token;
        if (eventid) url += '&id=' + eventid;
        if (eventType) url += '&eventType=' + eventType;
        axios.get(url)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.error && response.data.error.message === "Invalid Token") {
                    dispatch(logOut())
                }else{
                return response.data.result;
                }
            })
            .then((events) => {
                dispatch(dispatch_events(events))
            })
            .catch((err) => {
                console.error.bind("get products reducer.js error", err);
            })
    };
}

export const approvedAsset = (actionbody) => dispatch => {
    // dispatch(itemsIsLoading(true));

    axios.post(lambdaUrl + '/manageUserAssetActivity?appname=' + appname + '&env=' + environmentVariable + '&token=' + token, actionbody, { type: 'application/json' })
        .then((response) => {
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((approvedState) => {

            dispatch(approvedProps(approvedState))
        })
        .catch((err) => {
            console.error.bind("approvedAsset reducer.js error", err);
        })
};


export const getPricing = (token, payment_method_nonce, price, arrayAssets, optionalAddress, optionalCity, quality, itemType, transactionType, sku, cardholderName, envoisku, isSingleEventPurchase) => dispatch => {
    dispatch(itemsIsLoading(true));
    token = localStorage.getItem('token');
    let url = lambdaUrl + '/assetPayment?appname=' + appname + '&env=' + environmentVariable + '&token=' + token + '&payment_method_nonce=' + payment_method_nonce + '&transactionType=' + transactionType + '&itemType=' + itemType + '&price=' + price + '&address=' + optionalAddress + '&city=' + optionalCity + '&quality=' + quality + '&userassetType=' + itemType;
    if (sku) {
        url = url + "&sku=" + sku;
    }
    if(isSingleEventPurchase){
        url = url + "&isSingleEventPurchase=" + isSingleEventPurchase;
    }
    if (cardholderName) {
        url = url + "&cardholderName=" + cardholderName;
    }
    if (envoisku) {
        url = url + "&envoisku=" + envoisku;
    }
    axios.post(url, arrayAssets)
        .then((response) => {
            dispatch(itemsIsLoading(false));
            if (response.data.statusCode === 401) {
                let error = {
                    error: response.data.error?response.data.error:"Call Failed"
                }
                dispatch(assetPayment(error))
            } else {
                return response.data.result;
            }
        })
        .then((asset) => {
            dispatch(assetPayment(asset))
        })
        .catch((err) => {
            let error = {
                error: "Call Failed"
            }
            dispatch(assetPayment(error))
            console.error.bind("getPricing reducer.js error", err);
        })
};

export const getAssetInfo = (assetid, token) => dispatch => {
    token = localStorage.getItem('token');
    var isToken = '';
    if (assetid && token) {
         isToken = '/assetinfo?appname=' + appname + '&env=' + environmentVariable + '&event_id=' + assetid + '&token=' + token + '&scrubbing=' + scrubbing;
    } else {
         isToken = '/assetinfo?appname=' + appname + '&env=' + environmentVariable + '&assetid=' + assetid + '&scrubbing=' + scrubbing;
    }
    if (window.locationUrl) {
        isToken += window.locationUrl || ""
    }

    dispatch(itemsIsLoading(true));
    axios.post(lambdaUrl + isToken)
        .then((response) => {
            if (response.data.statusCode === 401) {
                $(document).ready(function () {
                    //  window.location = "/notfound";
                });
            }
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((asset) => {

            dispatch(assetinfo(asset))
        })
        .catch((err) => {
            console.error.bind("getAssetInfo reducer.js error", err);
        })
};

export const getEventInfo = (assetid) => dispatch => {
    token = localStorage.getItem('token');
    if (assetid && token) {
        var isToken = '/eventInfo?appname=' + appname + '&env=' + environmentVariable + '&eventId=' + assetid + '&token=' + token
    } 
    if (window.locationUrl) {
        isToken += window.locationUrl || ""
    }

    dispatch(itemsIsLoading(true));
    axios.post(lambdaUrl + isToken)
        .then((response) => {
            if (response.data.statusCode === 401) {
                $(document).ready(function () {
                    //  window.location = "/notfound";
                });
            }
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((event) => {

            dispatch(eventinfo(event))
        })
        .catch((err) => {
            console.error.bind("getEventInfo reducer.js error", err);
        })
};

export const msToTime = (duration) => dispatch => {
    duration = duration * 1000;
    var seconds = parseInt((duration / 1000) % 60)
        , minutes = parseInt((duration / (1000 * 60)) % 60)
        , hours = parseInt((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
};

export const logOut = () => dispatch => {
    localStorage.clear();
    window.location = '/';
};
