
/***
**Module Name: aboutus
 **File Name :  About.js
 **Project :    Zeus
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  Hemababu
 **author :  Hari
 **author :  Narasimha
 **author :  Rajesh
 **license :
 **version :  1.0.0 
 **Created on :
 **Created on: Oct 20 2017
 **Last modified on: June 21 2018
 **Description : Contains details about the zeus organization. 
 ***/
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import OktaSignIn from '@okta/okta-signin-widget';
import $ from 'jquery';
import { Auth0LockPasswordless } from 'auth0-lock';
import { Auth0Lock } from 'auth0-lock';
class auth0signUp extends Component {
    constructor(props) {
        super(props);

        if (window.siteConfig.authentication && window.siteConfig.authentication.key === "auth0" && window.clientId && window.domain) {
            let defaultOptions = {
                container: 'logincontainer',
                configurationBaseUrl: 'https://cdn.auth0.com',
                auth: {
                    responseType: 'token id_token',
                },
                theme: {
                    logo: window.auth0Logo
                }
            };
            if (window.enablePasswordLess === true)
                window.signinLock = new Auth0LockPasswordless(window.clientId, window.domain, defaultOptions);
            else
                window.signinLock = new Auth0Lock(window.clientId, window.domain, defaultOptions);
            defaultOptions["container"] = 'signupcontainer';

            if (window.enablePasswordLess === true)
                window.signupLock = new Auth0LockPasswordless(window.clientId, window.domain, defaultOptions);
            else
                window.signupLock = new Auth0Lock(window.clientId, window.domain, defaultOptions);
        } 
        // else if (oktaLogin && oktadomain && oktaclientId) {

        //     oktaWidget = new OktaSignIn({
        //         logo: logoImage,
        //         el: '#osw-container',
        //         baseUrl: oktadomain, // https://dev-852395.oktapreview.com  https://sanchaninfo.okta.com
        //         clientId: oktaclientId,
        //         redirectUri: window.location.protocol + "//" + window.location.host + '/',
        //         authParams: {
        //             issuer: oktadomain,
        //             responseType: ['id_token', 'token']
        //         },
        //         scope: 'openid profile email'
        //     });
        // }
    }

    componentDidUpdate() {
        if (this.props.clickAccess) {
            this.signupLock()
        }
    }
    signupLock(e) {
        e && e.preventDefault();
        localStorage.setItem("showDiv", this.props.hiddenDiv)
        localStorage.setItem("removeHidden", this.props.removeHidden)

        if (window.siteConfig.authentication && window.siteConfig.authentication.key === "auth0") {
            $("." + this.props.hiddenDiv).hide();
            $("#" + this.props.removeHidden).removeClass("hidediv")

            var options = {
                closable: false,
                languageDictionary: {
                    emailInputPlaceholder: "Enter Email",
                    passwordInputPlaceholder: "Enter Password",
                    signupTitle: 'Create your account with',
                    signUpSubmitLabel: 'Sign Up'
                },
                allowLogin: false
            };
            if (window.enablePasswordLess === true)
                window.signupLock.show({ passwordlessMethod: 'link' }, options);
            else
                window.signupLock.show(options);
        } else if (window.cognitoLogin) {
            if (window.cognitoTokenType === "code")
                window.location = window.signupCognitoUrl + "?client_id=" + window.cognitoClientId + "&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=" + window.location.origin
            else
                window.location = window.signupCognitoUrl + "?client_id=" + window.cognitoClientId + "&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=" + window.location.origin
        } else if (window.oktaLogin) {
            $("." + this.props.hiddenDiv).hide();
            $("#" + this.props.removeHidden).removeClass("hidediv");
            window.oktaWidget.show();
        } else if (window.siteConfig.authentication && window.siteConfig.authentication.key === "novi") {
            let novibaseURL = window.siteConfig.authentication.novi.baseUrl
            let noviClientId = window.siteConfig.authentication.novi.clientId

            window.location = novibaseURL + "/oauth/authorize?client_id=" + noviClientId + "&scope=openid&response_type=code%20token%20id_token&redirect_uri=" + window.location.origin
            
        }

    }
    render() {
        const { buttonHide, cssClass, labelText } = this.props

        return (
            !buttonHide ? window.siteConfig.authentication && (window.siteConfig.authentication.key === "novi" || window.siteConfig.authentication.key === "okta") ? null : <a href="#javascript" style={{ "cursor": "pointer" }} onClick={e => this.signupLock(e)} className={cssClass}>{labelText}</a>
                : null
        )
    }
}


export default connect()(auth0signUp);