import {
 
    GET_ASSET_TAGS,
    ITEMS_IS_LOADING,
    GET_ALL_PARTNERS,
    ACTIVATION_CODE,
    GET_USER,
    ADD_PROFILE,
    GET_COUNTRIES,
    BRAINTREE_CUSTOMER,
    GET_ADDRESS,
    GENARATE_ACTIVATION_CODE,
    GET_REGIONS,
    GENARATE_USER_RELATION,
    GET_EVENTS,
    APPROVED_ASSET,
    ASSET_PAYMENT,
    GET_ASSETINFO,
    GET_EVENTINFO
} from './ActionTypes'


/////////////////ACTIONS//////////////
export const websiteDefaults = (websiteDefaultData) => ({ type: GET_ASSET_TAGS, websiteDefaultData });
export const itemsIsLoading = (loading) => ({ type: ITEMS_IS_LOADING, loading });
export const Getpartners = (partners) => ({ type: GET_ALL_PARTNERS, partners });
export const activationCodeProps = (activationCodeState) => ({ type: ACTIVATION_CODE, activationCodeState });
export const userinfo = (user) => ({ type: GET_USER, user });
export const dispatch_addprofile = (addprofile) => ({ type: ADD_PROFILE, addprofile });
export const dispatch_countries = (countries) => ({ type: GET_COUNTRIES, countries });
export const braintreeCustomer = (braintreeCustomerState) => ({ type: BRAINTREE_CUSTOMER, braintreeCustomerState });
export const dispatch_address = (address) => ({ type: GET_ADDRESS, address });
export const genarateActivationCodeProps = (genarateActivationCodeState) => ({ type: GENARATE_ACTIVATION_CODE, genarateActivationCodeState });
export const regions = (regionsState) => ({ type: GET_REGIONS, regionsState });
export const genarateUserRelationProps = (UserRelationData) => ({ type: GENARATE_USER_RELATION, UserRelationData });
export const dispatch_events = (events) => ({ type: GET_EVENTS, events });
export const approvedProps = (approvedState) => ({ type: APPROVED_ASSET, approvedState });
export const assetPayment = (asset) => ({ type: ASSET_PAYMENT, asset });
export const assetinfo = (asset) => ({ type: GET_ASSETINFO, asset });
export const eventinfo = (event) => ({ type: GET_EVENTINFO, event });