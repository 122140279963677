import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { browserHistory } from 'react-router';
import * as config from '../config';
// import './App.css';
import {connect} from 'react-redux';
import Analytics from 'analytics';
// import googleAnalytics from '@analytics/google-analytics'
import {  AnalyticsProvider as AppAnalyticsProvider  } from 'use-analytics'

// Components
import ChimeSdkWrapper from './chime/ChimeSdkWrapper';
import Home from './chimeWeb/Welcome';
import Join from './chimeWeb/Join';
import Meeting from './chimeWeb/Meeting';
import End from './chimeWeb/End';
import LandingPage from './authentication/LandingPage';
import { UserProvider } from './userContext';
import AddDevice from "./authentication/AddDevice";
import CreateAnAccount from './authentication/CreateAnAccount';
import AlertPopup from './alertPopup';
import DotationPayment from "./payment/paymentDonation";
import payment from './payment/buyAsset';
import SuccessBought from './SuccessBought';
import Loader from './Loader';
import SubscriptionPayment from './authentication/subscriptionPayment';
import eventinfo from './chimeWeb/EventInfo';

import { getWebsiteDefault } from '../redux/reducers/reducer';


class App extends React.Component {
  constructor(props) {
    super(props);

    const envoiAnalyticsPlugins = {
      name: 'envoi-analytics-plugin',
      page: ({ payload }) => {
        console.debug('envoi-analytics-plugin.page fired', { payload })
      },
      track: ({ payload }) => {
        console.debug('envoi-analytics-plugin.track', { payload })
      }
    }

    const appAnalyticsConfig = Analytics({
      app: 'envoi-watch-party',
      plugins: [
        envoiAnalyticsPlugins
      ]
    });

    this.state = {
      appAnalyticsConfig,
      isLoaded: false,
      siteConfig: {}
    }
  }
  componentDidMount() {
    this.props.getWebsiteDefault();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.websiteDefaultData !== this.props.websiteDefaultData) {
      let siteConfig = this.props.websiteDefaultData.config || {};
      window.siteConfig = siteConfig;
      let themeStyle = ((siteConfig.screening || {}).themeStyle || '');
      let imageSuffix = "?auto=compress,format";
      window.imagePrefix = siteConfig.appResourcesUrl + siteConfig.appResourcesPath;
      window.imagePrefix = siteConfig.appResourcesUrl + siteConfig.appResourcesPath;
      window.imageSuffix = imageSuffix;
      window.imagesPath = window.siteConfig && window.siteConfig.appResourcesUrl + window.siteConfig.appResourcesPath;
      window.themePrefix = themeStyle ? 'images/' + themeStyle + '/' : 'images/';
      window.imagesAssetResourcesPrefix = siteConfig.commonResourcesUrl + "screening/";
      window.countryCode = siteConfig.countryCode;
      window.accountType = siteConfig.accountType || "";
      window.paymentEnable = (siteConfig.subscription || {}).enable;
      window.preLoader = window.imagePrefix + 'screening/images/preloader.png' + imageSuffix;
      this.setState({imagePrefix: window.imagePrefix, imageSuffix:"?auto=compress,format", siteConfig, isLoaded: true})
    }
  }
  
  render() {
    const { appAnalyticsConfig, imagePrefix, siteConfig, isLoaded } = this.state;
    const chime = new ChimeSdkWrapper();
    const baseHref = config.BASE_HREF;

    return (
      <AppAnalyticsProvider instance={appAnalyticsConfig}>
        {isLoaded ?
        <div className="App full-width full-height">
          <UserProvider value={{ imagePrefix: imagePrefix, siteConfig: siteConfig }}>
            <Router history={browserHistory}>
              <Switch>
                <Route exact path="/end" >
                  <End />
                </Route>
                <Route path={`${baseHref}/meeting`}>
                  <Meeting
                    chime={chime}
                  />
                </Route>
                <Route path={`${baseHref}/join`}>
                  <Join
                    chime={chime}
                  />
                </Route>
                <Route path={`${baseHref}/home`}>
                  <Home
                    chime={chime}
                  />
                </Route>
                <Route path="/create" component={CreateAnAccount} />

                <Route path={`${baseHref}/adddevice`}>
                  <AddDevice
                    chime={chime}
                  />
                </Route>
                <Route path="/eventinfo" component={eventinfo} />
                <Route path="/payment/:assetid" component={payment} />
                <Route path="/paymentdonation" component={DotationPayment} />
                <Route path="/boughtsuccess" component={SuccessBought} />
                <Route path="/subpayment/:planId" component={SubscriptionPayment} />
                <Route path={`${baseHref}`}>
                  <LandingPage
                    chime={chime}
                  />
                </Route>
              </Switch>
            </Router>
          </UserProvider>
          <AlertPopup />
        </div> : <Loader />}
      </AppAnalyticsProvider>
    );
  }
}
const mapState = ({ websiteDefaultData }) => ({ websiteDefaultData });
const mapDispatch = { getWebsiteDefault };
export default connect(mapState, mapDispatch)(App);

