import { createStore, applyMiddleware } from 'redux';
import reducer from './reducers/reducer';
import thunkMiddleware from 'redux-thunk';
// import {createLogger} from 'redux-logger';
import { loadState, saveState } from './localStorage';

const presistedState = loadState();

const store = createStore(
  reducer,
  presistedState,
  applyMiddleware(
    thunkMiddleware
  )
);

store.subscribe(()=> {
  saveState(store.getState());
})

// store.subscribe(()=> {
//   saveState({
//      featured: store.getState().featured 
//     });
// })
export default store;