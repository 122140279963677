import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as config from '../../config';
import { connect } from 'react-redux';
import { DefaultModality, MeetingSessionStatusCode } from 'amazon-chime-sdk-js';

// Components
import VideoPlayer from '../videoPlayer/VideoPlayer';
import Chat from '../chat/Chat';
import Controls from './Controls';
import Settings from './Settings';
import LocalVideo from './LocalVideo';
import RemoteVideoGroup from './RemoteVideoGroup';
import Error from './Error';
import { getEventInfo } from '../../redux/reducers/reducer';
// Styles
import './ChimeWeb.css';
import AlertPopup from '../payment/AlertPopup';
class Meeting extends Component {

  state = {
    meetingStatus: null, // Loading, Success or Failed
    showSettings: false,
    eventinfo: '',
    showError: false,
    errorMsg: '',
    playbackURL:'',
    showContentShare: false,
    showmessage:false,
    defaultVideoPlayer: 'Bitmovin',
    eventData:{}
  }

  constructor() {
    super();
    this.baseHref = config.BASE_HREF;
    this.ssName = '';

    this.audioElementRef = React.createRef();
    this.myVideoElement = React.createRef();
    this.contentShareElementRef = React.createRef();
    this.okClick = this.okClick.bind(this);
  }

  componentDidMount() {

    const contentShareObserver = {
      videoTileDidUpdate: tileState => {
        // Ignore a tile without attendee ID and videos.
        if (!tileState.boundAttendeeId || !tileState.isContent) {
          return;
        }

        const meetingSession = this.props.chime.meetingSession;
        const yourAttendeeId = meetingSession.configuration.credentials.attendeeId;

        // tileState.boundAttendeeId is formatted as "attendee-id#content".
        const boundAttendeeId = tileState.boundAttendeeId;

        // Get the attendee ID from "attendee-id#content".
        const baseAttendeeId = new DefaultModality(boundAttendeeId).base();
        if (baseAttendeeId === yourAttendeeId) {
          console.log('You called startContentShareFromScreenCapture');
        }
        try {
          const videoElement = this.contentShareElementRef.current;
          this.props.chime.audioVideo.bindVideoElement(tileState.tileId, videoElement);
          this.setState({ showContentShare: true })
        } catch (error) {
          console.error('Meeting.videoTileDidUpdate', { error });
        }

      },

      contentShareDidStart: () => {
        console.log('Screen share started');
        this.setState({ showContentShare: true })
      },

      contentShareDidStop: () => {
        // Chime SDK allows 2 simultaneous content shares per meeting.
        // This method will be invoked if two attendees are already sharing content
        // when you call startContentShareFromScreenCapture or startContentShare.
        console.log('Screen share stopped');
        this.setState({ showContentShare: false })
      }
    };


    const start = async () => {
      try {

        const qs = new URLSearchParams(this.props.location.search);
        const room = qs.get('room');
        // const room ='test'
        this.ssName = `chime[${room}]`;
        if (!room || !sessionStorage.getItem(this.ssName)) {
          this.props.history.push(`${this.baseHref}/`);
        }

        const ssData = JSON.parse(sessionStorage.getItem(this.ssName));
        if (config.DEBUG) console.log(ssData);

        this.username = ssData.username;
        this.title = ssData.title;
        this.role = ssData.role;
        this.item = ssData.item;
        

        if (!ssData.joinInfo) {
          this.joinInfo = await this.props.chime.createRoom(this.role, this.username, this.title, ssData.playbackURL);
          const data = {
            ...ssData,
            joinInfo: this.joinInfo
          }
          sessionStorage.setItem(this.ssName, JSON.stringify(data));
          this.playbackURL = this.joinInfo.PlaybackURL;
          //this.setState({playbackURL: this.playbackURL});
        } else {
          // Browser refresh
          this.joinInfo = ssData.joinInfo;
          this.playbackURL = ssData.joinInfo.PlaybackURL;
          //this.setState({playbackURL: this.playbackURL});
          await this.props.chime.reInitializeMeetingSession(this.joinInfo, this.username);
        }
        if(ssData.item && ssData.item.event_id){
          this.props.getEventInfo(ssData.item.event_id);
         }

        this.setState({ meetingStatus: 'Success' });

        this.props.chime.audioVideo.addObserver({
          audioVideoDidStop: async (sessionStatus) => {
            if (sessionStatus.statusCode() === MeetingSessionStatusCode.AudioCallEnded) {
              const whereTo = `${this.baseHref}/${this.role === 'host' ? '' : 'end'}`;
              this.props.chime.leaveRoom(this.role === 'host');
              this.props.history.push(whereTo);
            }
          }
        });

        this.props.chime.audioVideo.addContentShareObserver(contentShareObserver);
        this.props.chime.audioVideo.addObserver(contentShareObserver);

        await this.props.chime.joinRoom(this.audioElementRef.current);
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
        this.setState({ meetingStatus: 'Failed' });
      }
    };

    start()
    .then(() => console.debug('Meeting started'));
  }
  componentDidUpdate(prevProps, prevState) {
    let self = this;
    if (prevProps.eventinfo !== this.props.eventinfo) {
      this.setState({eventData: this.props.eventinfo});
      if(this.props.eventinfo && this.props.eventinfo.input && this.props.eventinfo.defaultVideoPlayer == 'Envoi Player'){
        this.setState({eventinfo: '&'+[this.props.eventinfo.input.type]+'='+this.props.eventinfo.input.id, defaultVideoPlayer: this.props.eventinfo.defaultVideoPlayer});
        if(this.props.eventinfo && this.props.eventinfo.playerUrls && this.props.eventinfo.playerUrls.length > 0){
       
          let playerUrl = '';
          let playerUrls = this.props.eventinfo.playerUrls[0];
          if(playerUrls){
             playerUrl = (playerUrls.hls) || (playerUrls.m3u8_Url) || (playerUrls.progressive) || (playerUrls.vdms_url) || (playerUrls.iconik_proxies_url) || (playerUrls.mp4url) || ( playerUrls.eluvio_url || playerUrls.Mp4_proxy_URL);
           }
          self.playbackURL = playerUrl;
          if(self.playbackURL){
            self.setState({playbackURL: self.playbackURL}, () =>
            console.log('this.playbackURL',this.joinInfo,this.username) 
            );
          }
        }
      }
      else if(this.props.eventinfo && this.props.eventinfo.playerUrls && this.props.eventinfo.playerUrls.length > 0){
       
        let playerUrl = '';
        let playerUrls = this.props.eventinfo.playerUrls[0];
        if(playerUrls){
           playerUrl = (playerUrls.hls) || (playerUrls.m3u8_Url) || (playerUrls.progressive) || (playerUrls.vdms_url) || (playerUrls.iconik_proxies_url) || (playerUrls.mp4url) || ( playerUrls.eluvio_url || playerUrls.Mp4_proxy_URL);
         }
        console.log('here',playerUrl);
        self.playbackURL = playerUrl;
        if(self.playbackURL){
          this.setState({playbackURL: self.playbackURL}, () =>
          console.log('this.playbackURL',this.joinInfo,this.username) 
        //  this.props.chime.reInitializeMeetingSession(this.joinInfo, this.username)
            );
        }else{
          self.setState({
            showmessage: true
        })
        }
      }else{
        self.setState({
          showmessage: true
      })
      }
    }
  }

  /*
   * Settings
   */

  openSettings = () => {
    this.setState({ showSettings: true });
  }

  closeSettings = () => {
    this.setState({ showSettings: false });
  }

  handleClick = (e) => {
    const { showSettings } = this.state;
    if (showSettings) {
      let node = e.target;
      let isModal = false;
      while (node) {
        if (node && node.classList && node.classList.contains('modal__el')) {
          isModal = true;
          break;
        }
        node = node.parentNode;
      }
      if (!isModal) {
        this.setState({ showSettings: false });
      }
    }
  }

  saveSettings = (playbackURL, currentAudioInputDevice, currentAudioOutputDevice, currentVideoInputDevice) => {
    this.setState({
      showSettings: false,
      currentAudioInputDevice,
      currentAudioOutputDevice,
      currentVideoInputDevice
    });
  }

  setMetadataId = (metadataId) => {
    this.setState({ metadataId });
  }

  setErrorMsg = errorMsg => {
    this.setState({ errorMsg, showError: true });
  }

  closeError = () => {
    this.setState({ showError: false });
  }
  okClick = async () => {
    let self = this;
    self.setState({ showmessage: false });
    await this.props.chime.leaveRoom(this.props.role === 'host');
    sessionStorage.removeItem(this.props.ssName);
    const whereTo = `${this.props.baseHref}/${this.props.role === 'host' ? '/home' : 'join?room=' + this.props.title}`;
    this.props.history.push(whereTo);
}
  layout = () => {
    if (this.state.meetingStatus !== 'Success') {
      return;
    }

    return (
      <div className="app-grid" onClick={this.handleClick}>
        <div className="main-stage">
          <div className="cams pos-relative">
            <LocalVideo
              chime={this.props.chime}
              joinInfo={this.joinInfo}
            />
            <RemoteVideoGroup
              chime={this.props.chime}
              joinInfo={this.joinInfo}
            />
          </div>

          <video ref={this.contentShareElementRef} style={{ display: this.state.showContentShare ? 'block' : 'none'}} />

          {((this.state.playbackURL || this.state.defaultVideoPlayer === 'Envoi Player') && !this.state.showContentShare) && <VideoPlayer
            setMetadataId={this.setMetadataId}
            videoStream={this.state.playbackURL}
            eventinfo={this.state.eventinfo}
            defaultVideoPlayer={this.state.defaultVideoPlayer}
            item={this.item}
            title={this.title}
          />}
          
          <Controls
            chime={this.props.chime}
            baseHref={this.baseHref}
            ssName={this.ssName}
            title={this.title}
            openSettings={this.openSettings}
            role={this.role}
            history={this.props.history}
            myVideoElement={this.myVideoElement}
            item={this.item}
            defaultVideoPlayer={this.state.defaultVideoPlayer}
            showContentShare={this.state.showContentShare}
          />
        </div>
        <Chat
          chime={this.props.chime}
          title={this.title}
          username={this.username}
          joinInfo={this.joinInfo}
          item={this.item}
          eventData={this.state.eventData}
        />
        {this.state.showmessage &&
                    <AlertPopup  message="Sorry, this video is not available for viewing yet." closePopup={this.okClick} title='NOT AVAILABLE' imagePath="images/states_of_applications/warning_gray.png" showClose={true}/>
                   }
        {this.state.showSettings && (
          <Settings
            chime={this.props.chime}
            joinInfo={this.joinInfo}
            saveSettings={this.saveSettings}
          />
        )}
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>

        <audio ref={this.audioElementRef} style={{ display: 'none' }} />

        {this.layout()}

        {this.state.showError && (
          <Error
            closeError={this.closeError}
            errorMsg={this.state.errorMsg}
          />
        )}
      </React.Fragment>
    )
  }
}

Meeting.propTypes = {
  chime: PropTypes.object
};
const mapState = ({eventinfo }) => ({eventinfo});
const mapDispatch = {getEventInfo};
export default  withRouter(connect(mapState, mapDispatch)(Meeting));
//export default withRouter(Meeting);
